import axios from "axios";
import msg from "../../util/msg.js";

export function list(param) {
  return axios.get("/period/list", { params: param });
}

export function getSaleStatusTag(saleStatus){
    if(saleStatus == 'WAIT'){
        return 'info';
    }
    if(saleStatus == 'PROCESSING'){
        return 'success';
    }
    if(saleStatus == 'STOP'){
        return 'warning';
    }
    if(saleStatus == 'END'){
        return 'danger';
    }
}

function checkSelectIds(selectIds){
    if(selectIds == null || selectIds.length <= 0){
        msg.fail("请至少选择一个期号");
        return false;
    }

    return true;
}

export function edit(period){
    axios.get("/period/edit", period).then(data=>{
        msg.success();
    });
}

export function editST(periodIds, saleStatus){
    if(!checkSelectIds(periodIds)){
        return;
    }
    let param = {
        periodIds,
        saleStatus
    }
    axios.post("/period/editST", param).then(data=>{
        msg.success();
    });
}

export function fetchResult(periodIds){
    if(!checkSelectIds(periodIds)){
        return;
    }
    axios.post("/period/fetchResult", {periodIds}).then(data=>{
        msg.success();
    });
}

export function updateBetResult(periodIds){
    if(!checkSelectIds(periodIds)){
        return;
    }
    axios.post("/period/updateBetResult", {periodIds}).then(data=>{
        msg.success();
    });

}


export function sendPrize(periodIds){
    if(!checkSelectIds(periodIds)){
        return;
    }
    axios.post("/period/sendBIPrize", {periodIds}).then(data=>{
        msg.success();
    });
}

export function editResult(id, data){
    data.id = id;
    axios.post("/period/editResult", data).then(data=>{
        msg.success();
    });
}


export function statPeriodBI(id){
    return axios.get("/period/statBI/" + id);
}


