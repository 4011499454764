<template>
    <div id="period-list">
        <el-form :inline="true" :model="form">
            <el-form-item label="ID">
                <el-input v-model="form.id" placeholder="ID"></el-input>
            </el-form-item>
            <el-form-item label="彩种">
                <el-select v-model="form.lotteryType" placeholder="请选择" clearable>
                    <el-option v-for="lotteryType in Object.keys(LOTTERY_TYPE   )" :key="lotteryType"
                        :label="LOTTERY_TYPE[lotteryType]" :value="lotteryType">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="官方编号">
                <el-input v-model="form.officialNo" placeholder="官方编号"></el-input>
            </el-form-item>
            <el-form-item label="销售状态">
                <el-select v-model="form.saleStatus" placeholder="请选择" clearable>
                    <el-option v-for="saleStatus in Object.keys(SALE_STATUS)" :key="saleStatus"
                        :label="SALE_STATUS[saleStatus]" :value="saleStatus">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="loadList">查询</el-button>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-button type="primary" @click="editST(selectIds, 'PROCESSING')">开始销售</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="primary" @click="editST(selectIds, 'STOP')">暂停销售</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="primary" @click="editST(selectIds, 'END')">结束销售</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="success" @click="fetchResult(selectIds)">捉取结果</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="warning" @click="updateBetResult(selectIds)">开奖</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="warning" @click="sendPrize(selectIds)">派奖</el-button>
        <el-divider></el-divider>

        <el-table :data="page.list" border @selection-change="handleSelectChange">
            <el-table-column type="selection" width="50">
            </el-table-column>
            <el-table-column label="ID" width="130">
                <template v-slot="row">
                    <span>{{ row.row.id }}</span><el-divider direction="vertical"></el-divider>
                    <span class="no_span">#{{ row.row.no }}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="no" label="编号" width="60"> -->
            <!-- </el-table-column> -->
            <el-table-column prop="officialNo" label="官方编号" width="100">
            </el-table-column>
            <el-table-column label="彩种" width="150">
                <template v-slot="row">
                    {{ LOTTERY_TYPE[row.row.lotteryType] }}
                </template>
            </el-table-column>
            <el-table-column label="销售状态" width="150">
                <template v-slot="row">
                    <el-tag :type="getSaleStatusTag(row.row.saleStatus)">{{
                        SALE_STATUS[row.row.saleStatus] }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="开售时间">
                <template v-slot="row">
                    <span class="st_span">{{ row.row.startTime }} </span>
                </template>
            </el-table-column>
            <el-table-column label="截止时间">
                <template v-slot="row">
                    <span class="et_span">{{ row.row.endTime }}</span>
                </template>
            </el-table-column>
            <el-table-column label="开奖结果">
                <template v-slot="row">
                    <Bingo18Result v-if="row.row.lotteryType == 'BINGO18' && row.row.periodResult != null"
                        :periodResult="row.row.periodResult"></Bingo18Result>
                </template>
            </el-table-column>
            <el-table-column label="投注统计">
                <template v-slot="row">
                    <PeriodBIStat :statInfo="row.row.statInfo"></PeriodBIStat>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template v-slot="row">
                    <el-popover placement="left" trigger="click" width="500">
                        <Bingo18ResultEdit v-if="row.row.lotteryType == 'BINGO18'" :periodId="row.row.id">
                        </Bingo18ResultEdit>
                        <el-button slot="reference">修改结果</el-button>
                    </el-popover>
                    <el-divider direction="vertical"></el-divider>
                    <el-button @click="goBI(row.row.id)">查看投注</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="text-align: center" :page-count="page.totalPage" :current-page="form.pageNo"
            layout="prev, pager, next" @current-change="changePage">
        </el-pagination>
    </div>
</template>
<script>
import { list, getSaleStatusTag, editST, updateBetResult, sendPrize, fetchResult } from './period';

import { SALE_STATUS, LOTTERY_TYPE } from "../../util/constants";

import Bingo18ResultEdit from "../../components/Bingo18ResultEdit"
import Bingo18Result from "../../components/Bingo18Result"
import PeriodBIStat from "../../components/PeriodBIStat.vue"

export default {
    name: "PeriodList",
    components: {
        Bingo18ResultEdit,
        Bingo18Result,
        PeriodBIStat,
    },
    data() {
        return {

            LOTTERY_TYPE,
            SALE_STATUS,

            form: {
                pageNo: 1,
                pageSize: 20,
                lotteryType: "BINGO18",
                saleStatus: "PROCESSING",
                officialNo: null,
            },

            page: {
                list: null,
                totalPage: null,
            },


            selectIds: []
        };
    },

    methods: {
        editST,
        getSaleStatusTag,
        updateBetResult,
        sendPrize,
        fetchResult,
        loadList() {
            list(this.form).then((data) => {
                this.page = data;
            });
        },

        changePage(val) {
            this.form.pageNo = val;
            this.loadList();
        },

        handleSelectChange(val) {
            let ids = []
            val.forEach(element => {
                ids.push(element.id);
            });
            console.log(ids);
            this.selectIds = ids;
        },

        goBI(periodId) {
            this.$router.push({
                path: '/bi-list?periodId=' + periodId,
            });
        },

    },

    created() {
        this.loadList();
    },

};
</script>

<style scoped>
.no_span {
    color: #7b7b7b;
    font-size: smaller;
}

.st_span {
    color: #7bdf34;
}

.et_span {
    color: #e44e4e;
}
</style>
