<template>
    <div id="bingo18Result">
        <el-descriptions :column="3" size="small" border>
            <el-descriptions-item label="第一个">
                {{ periodResult.first }}
            </el-descriptions-item>
            <el-descriptions-item label="第二个">
                {{ periodResult.second }}
            </el-descriptions-item>
            <el-descriptions-item label="第三个">
                {{ periodResult.third }}
            </el-descriptions-item>
            <el-descriptions-item label="总数">
                {{ periodResult.total }}
            </el-descriptions-item>
            <el-descriptions-item label="大中小">
                {{ B18_GDS[periodResult.gds] }}
            </el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
import { B18_GDS } from "../util/constants";

export default {
    name: "Bingo18Result",
    props: {
        periodResult: {
            type: Object,
        }
    },
    data() {
        return {
            B18_GDS
        }
    },
};
</script>