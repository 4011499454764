<template>
    <div id="periodBIStat">
        <template v-if="statInfo.betResultStatBOList != null && statInfo.betResultStatBOList.length > 0">
            <el-table border :data="statInfo.betResultStatBOList" :show-header="false">
                <el-table-column label="开奖状态">
                    <template v-slot="row">
                        {{ BET_RESULT[row.row.betResult] }}
                    </template>
                </el-table-column>
                <el-table-column prop="nums" label="数量">
                </el-table-column>
            </el-table>
        </template>

        <template v-if="statInfo.prizeStatusStatBOList != null && statInfo.prizeStatusStatBOList.length > 0">
            <el-table border :data="statInfo.prizeStatusStatBOList" :show-header="false">
                <el-table-column label="派奖状态">
                    <template v-slot="row">
                        {{ PRIZE_STATUS[row.row.prizeStatus] }}
                    </template>
                </el-table-column>
                <el-table-column prop="nums" label="数量">
                </el-table-column>
            </el-table>
        </template>

    </div>
</template>

<script>

import { PRIZE_STATUS, BET_RESULT } from '../util/constants'

export default {
    name: "PeriodBIStat",

    props: {
        statInfo: {
            type: Object
        },
    },

    data() {
        return {
            PRIZE_STATUS,
            BET_RESULT,
        };
    },
};
</script>