<template>
    <div id="bingo18ResultEdit">
        <el-form label-position="right" label-width="100px" :model="editResultForm">
            <el-form-item label="期号ID">
                {{ periodId }}
            </el-form-item>
            <el-form-item label="第一个结果">
                <el-input v-model="editResultForm.first"></el-input>
            </el-form-item>
            <el-form-item label="第二个结果">
                <el-input v-model="editResultForm.second"></el-input>
            </el-form-item>
            <el-form-item label="第三个结果">
                <el-input v-model="editResultForm.third"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="editResult(periodId, editResultForm)">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { editResult } from '../views/period/period.js'

export default {
    name: "Bingo18ResultEdit",

    props: {
        periodId:{
            type:Number
        },
    },

    methods: {
        editResult,
    },

    data() {
        return {
            editResultForm: {
                id:  null,
                first: null,
                second: null,
                third: null,
            }
        };
    }
};
</script>